import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import TopHeader from "../../../components/top-header"

import { Row, Container, Col } from 'react-bootstrap/'
import RetmesJob from "../../../images/is-emri-planlamasi.svg"
import { useStaticQuery, graphql } from "gatsby"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"

const Isemri = ({ intl }) => {
  const title = intl.formatMessage({ id: "modules_content4__9" })
  const data = useStaticQuery(graphql`
query {
  headerImage: file(relativePath: { eq: "modules/Yonetim.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`)
  return <Layout >
    <TopHeader />
    <SEO title={title} />
    <Container>
    
      <div className='image-wrapper' fluid>
        <Img style={{ borderRadius: '1rem' }} alt="retmes yönetim" fluid={data.headerImage.childImageSharp.fluid} />
        <h2 className='image-wrapper-text'><FormattedMessage id="modules_title4__1" /></h2>
      </div>
      <h1 className='page-title'>{title}</h1>
      <Row className='content-page-row'>
        <Col >
          <p className='content-page-p'>
            <FormattedMessage id="modules_iep_desc" />
          </p>
        </Col>
      </Row>
      <Row>
        <img style={{ width: '-webkit-fill-available' }} src={RetmesJob} alt={title} />
      </Row>
      <Row style={{ height: '50vh' }}>
      </Row>
    </Container>
  </Layout>
}

export default injectIntl(Isemri) 